import styled from "@emotion/styled"
import { fixDragPreviewOverlappingContentStyle } from "@ninjaone/components"
import React, { memo, useRef } from "react"
import { useDrag, useDrop } from "react-dnd"

const elementDndType = "DragableElement"

const StyledItem = styled.div`
  ${fixDragPreviewOverlappingContentStyle}
`

const DragableElement = ({ index, element, moveElement, onRemoveElement, renderElement, isDisabled, endDrag }) => {
  const removeElement = (e, element) => {
    e.preventDefault()
    e.stopPropagation()

    onRemoveElement(element)
  }

  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: elementDndType,
    hover: (item, monitor) => {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveElement(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: elementDndType,
    item: { element, index },
    canDrag: () => !isDisabled,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => endDrag?.(),
  })
  const opacity = isDragging ? 0 : 1

  drag(drop(ref))

  return (
    <StyledItem ref={ref} style={{ opacity }} data-testid="test-dragable-item">
      {renderElement(element, removeElement, isDragging, index)}
    </StyledItem>
  )
}

export default memo(DragableElement)
