import { forwardRef, useMemo } from "react"
import PropTypes from "prop-types"
import { compose, filter, pluck, reject } from "ramda"
import { v4 as uuidv4 } from "uuid"

import { Item, ItemText, Portal, Root, Trigger, Viewport } from "@radix-ui/react-select"
import { Root as VisuallyHidden } from "@radix-ui/react-visually-hidden"
import isPropValid from "@emotion/is-prop-valid"
import styled from "@emotion/styled"

import Checkbox from "../Checkbox"
import Text from "../Text"
import Tooltip from "../Tooltip"
import { ErrorIconTooltip } from "../IconTooltips"

import tokens from "@ninjaone/tokens"
import { MagnifyingGlass } from "@ninjaone/icons"

import {
  StyledBaseContent,
  StyledItem,
  StyledOptionTextContainer,
  StyledTrigger,
  triggerTooltipClassName,
  useSelect,
} from "./utils"

import {
  isEnterKey,
  isSpaceKey,
  isTabKey,
  localized,
} from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils"

import { SelectIconComponent } from "./Components/SelectIcon"
import { SelectLabelComponent } from "./Components/SelectLabel"
import { SelectValueComponent } from "./Components/SelectValue"
import { isRequiredIf } from "@ninjaone/utils"

const StyledSelectWrapper = styled("div", {
  shouldForwardProp: prop => isPropValid(prop) || prop !== "hasTriggerTooltip",
})`
  ${({ hasTriggerTooltip }) =>
    hasTriggerTooltip &&
    `

  .${triggerTooltipClassName}:has([data-ninja-select-trigger][data-state="open"]) {
    + div > [data-ninja-tooltip-content] {
      visibility: hidden;
    }
  }`}
`

const StyledTriggerButtonWrapper = styled(Trigger)`
  padding: 0;
  width: 100%;

  border: none;
  border-radius: 0;

  background-color: transparent;
  outline-color: ${({ theme }) => theme.colorForegroundFocus};

  &:not([data-disabled]) {
    cursor: pointer;
  }
`

const StyledIconContainer = styled.div`
  gap: ${tokens.spacing[1]};
  display: flex;
  align-items: center;
`

const StyledMultiContent = styled(StyledBaseContent)`
  display: grid;
`

const StyledMultiOption = styled(Item)`
  outline: none;
  height: 38px;

  display: grid;
  align-items: center;
  justify-content: start;

  grid-auto-flow: column;
  grid-column-gap: ${tokens.spacing[3]};
  grid-auto-columns: auto;

  cursor: pointer;
  padding: 0 ${tokens.spacing[2]};

  border-radius: ${tokens.borderRadius[1]};
  z-index: 1000;

  &[data-highlighted] {
    color: inherit;
    background: ${({ theme }) => theme.colorForegroundHover};
  }
`

const StyledNoOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${tokens.spacing[1]};
  padding: ${tokens.spacing[4]} ${tokens.spacing[2]};
`

const SelectContent = forwardRef(
  (
    {
      size,
      value,
      onClose,
      isMulti,
      options,
      onChange,
      alignRight,
      listboxMinWidth,
      listboxZIndex,
      listboxMaxHeight,
      valueSelectorKey,
      onPointerDownOutside,
      onCloseAutoFocus,
      sideOffset,
      buttonRenderer,
      noOptionsText,
    },
    fowardedRef,
  ) => {
    const commonProps = {
      sideOffset,
      listboxZIndex,
      buttonRenderer,
      listboxMinWidth,
      listboxMaxHeight,
      ref: fowardedRef,
      onCloseAutoFocus,
      position: "popper",
      onPointerDownOutside,
      optionsLength: options?.length,
      "data-ninja-select-content": "",
      align: alignRight ? "end" : "start",
      onKeyDown: e => {
        if (isEnterKey(e)) {
          // This prevents closing the Modal when it has FocusTrap and the user presses Enter to select a Select option
          e.preventDefault()
        }
      },
    }

    if (options?.length === 0) {
      return (
        <StyledBaseContent {...commonProps}>
          <Viewport>
            <StyledNoOptionsWrapper>
              <MagnifyingGlass fontSize={tokens.typography.fontSize.body} color="colorTextWeakest" />
              <Text type="bodyXs" color="colorTextWeak">
                {noOptionsText}
              </Text>
            </StyledNoOptionsWrapper>
          </Viewport>
        </StyledBaseContent>
      )
    }

    if (isMulti) {
      return (
        <StyledMultiContent {...{ ...commonProps, onPointerDownOutside: onClose, onEscapeKeyDown: onClose }}>
          <Viewport>
            {options.map(({ value: _value, disabled: _disabled, labelToken, labelText }) => {
              const isSelected = valueSelectorKey
                ? value.some(selectedValue => selectedValue[valueSelectorKey] === _value[valueSelectorKey])
                : value.includes(_value)

              const onSelect = () =>
                onChange(
                  _value,
                  isSelected
                    ? reject(
                        selectedValue =>
                          valueSelectorKey
                            ? selectedValue[valueSelectorKey] === _value[valueSelectorKey]
                            : selectedValue === _value,
                        value,
                      )
                    : compose(
                        pluck("value"),
                        filter(
                          option =>
                            _value[valueSelectorKey] === option.value[valueSelectorKey] ||
                            value.some(
                              selectedValue => selectedValue[valueSelectorKey] === option.value[valueSelectorKey],
                            ),
                        ),
                      )(options),
                )

              return (
                <StyledMultiOption
                  disabled={_disabled}
                  onPointerDown={onSelect}
                  onKeyDown={e => {
                    if (e.code !== "Space") return

                    onSelect()
                  }}
                  key={valueSelectorKey ? _value[valueSelectorKey] : _value}
                >
                  <Checkbox
                    checked={isSelected}
                    onChange={({ event }) => event.stopPropagation()}
                    disabled={_disabled}
                  />
                  <Text forceRefreshOnLoad size="sm" token={labelToken} children={labelText} />
                </StyledMultiOption>
              )
            })}
          </Viewport>
        </StyledMultiContent>
      )
    }

    return (
      <StyledBaseContent {...commonProps}>
        <Viewport>
          {options.map(
            ({
              value: _value,
              disabled: _disabled,
              labelToken,
              labelText,
              LabelComponent,
              ariaDescription,
              ...rest
            }) => (
              <StyledItem
                {...{
                  key: _value,
                  value: _value,
                  disabled: _disabled,
                  "data-ninja-select-item": "",
                }}
                onClick={e => e.stopPropagation()}
              >
                {LabelComponent ? (
                  <ItemText>
                    <LabelComponent {...{ labelToken, labelText, ...rest }} />
                  </ItemText>
                ) : (
                  <ItemText asChild>
                    <StyledOptionTextContainer {...{ listboxMinWidth, size, buttonRenderer }}>
                      <Text forceRefreshOnLoad token={labelToken} children={labelText} type="body" />
                    </StyledOptionTextContainer>
                  </ItemText>
                )}

                {ariaDescription && <VisuallyHidden>{ariaDescription}</VisuallyHidden>}
              </StyledItem>
            ),
          )}
        </Viewport>
      </StyledBaseContent>
    )
  },
)

const SelectTrigger = ({
  size,
  value,
  ariaId,
  isOpen,
  ariaLabel,
  dataTestid,
  errorMessage,
  labelRenderer,
  listboxZIndex,
  buttonRenderer,
  placeholderLabel,
  placeholderToken,
  triggerTooltipLabel,
  hideTriggerTooltip,
  indicatorIconRenderer,
  preventValueChangeOnTriggerKeyDown,
  triggerMinWidth,
  hasAccessibleLabel,
  required,
  disabled,
}) => {
  const commonProps = {
    "data-value": value,
    "data-ninja-select-trigger": "",
    "aria-required": !disabled && required,
    onClick: e => e.stopPropagation(),

    ...(hasAccessibleLabel
      ? {
          "aria-labelledby": ariaId,
        }
      : {
          "aria-label": ariaLabel,
        }),

    ...(preventValueChangeOnTriggerKeyDown && {
      onKeyDown: event => {
        const isTryingToOpen = isEnterKey(event) || isSpaceKey(event) || isTabKey(event)

        if (!isTryingToOpen) {
          event.preventDefault()
        }
      },
    }),
  }

  const triggerContent = buttonRenderer ? (
    // The buttonRenderer element should NOT be a button.
    <StyledTriggerButtonWrapper {...commonProps}>{buttonRenderer()}</StyledTriggerButtonWrapper>
  ) : (
    <StyledTrigger
      data-testid={dataTestid}
      {...commonProps}
      {...{
        size,
        triggerMinWidth,
        error: errorMessage,
      }}
    >
      <SelectValueComponent {...{ isOpen, labelRenderer, placeholderLabel, placeholderToken }} />

      <StyledIconContainer>
        {errorMessage && <ErrorIconTooltip {...{ ariaId, error: errorMessage }} />}

        <SelectIconComponent {...{ indicatorIconRenderer }} />
      </StyledIconContainer>
    </StyledTrigger>
  )

  return triggerTooltipLabel ? (
    <Tooltip
      portal={false}
      label={triggerTooltipLabel}
      contentZIndex={listboxZIndex}
      hideTooltip={isOpen || hideTriggerTooltip}
      triggerClassName={triggerTooltipClassName}
    >
      {triggerContent}
    </Tooltip>
  ) : (
    triggerContent
  )
}

function Select({
  size,
  options,
  onFocus,
  labelId,
  onChange,
  disabled,
  labelText,
  labelToken,
  alignRight,
  defaultValue,
  titleRenderer,
  labelRenderer,
  buttonRenderer,
  listboxMinWidth,
  listboxZIndex,
  listboxMaxHeight,
  valueSelectorKey,
  placeholderLabel,
  placeholderToken,
  errorMessage = null,
  indicatorIconRenderer,
  value: controlledValue,
  popoverProps = { portal: false },
  triggerTooltipLabel,
  triggerAriaLabel,
  onPointerDownOutside,
  onCloseAutoFocus,
  sideOffset,
  preventValueChangeOnTriggerKeyDown,
  triggerMinWidth,
  required,
  tooltipText,
  onOpenChange,
  noOptionsText = localized("No options found"),
}) {
  const showLabel = labelId && (labelText || labelToken || titleRenderer)
  const ariaId = useMemo(() => {
    return uuidv4()
  }, [])

  const {
    isOpen,
    isMulti,
    setIsOpen,
    valueToUse,
    hideTriggerTooltip,
    handleOnOpenChange,
    handleOnValueChange,
  } = useSelect({ controlledValue, defaultValue, options, triggerTooltipLabel, onChange, onOpenChange })

  const _SelectContent = (
    <SelectContent
      {...{
        size,
        isMulti,
        options,
        onChange,
        alignRight,
        listboxMinWidth,
        listboxMaxHeight,
        listboxZIndex,
        valueSelectorKey,
        onClose: () => setIsOpen(false),
        // isMulti is only a controlled component.
        value: valueToUse,
        onPointerDownOutside,
        onCloseAutoFocus,
        sideOffset,
        buttonRenderer,
        noOptionsText,
      }}
    />
  )

  return (
    <StyledSelectWrapper onFocus={onFocus} hasTriggerTooltip={!!triggerTooltipLabel}>
      {showLabel && (
        <SelectLabelComponent {...{ ariaId, disabled, labelText, labelToken, required, titleRenderer, tooltipText }} />
      )}

      <Root
        {...{
          disabled,
          onOpenChange: handleOnOpenChange,
          ...(!isMulti
            ? {
                value: valueToUse,
                onValueChange: handleOnValueChange,
              }
            : { open: isOpen }),
        }}
      >
        <SelectTrigger
          {...{
            ariaId,
            size,
            isOpen,
            errorMessage,
            labelRenderer,
            listboxZIndex,
            buttonRenderer,
            placeholderToken,
            placeholderLabel,
            value: valueToUse,
            indicatorIconRenderer,
            triggerTooltipLabel,
            hideTriggerTooltip,
            preventValueChangeOnTriggerKeyDown,
            dataTestid: labelId,
            triggerMinWidth,
            ariaLabel: triggerAriaLabel,
            hasAccessibleLabel: !titleRenderer && (!!labelText || !!labelToken),
            required,
            disabled,
          }}
        />

        {popoverProps?.portal ? <Portal>{_SelectContent}</Portal> : _SelectContent}
      </Root>
    </StyledSelectWrapper>
  )
}

Select.propTypes = {
  /**
   * The disabled state for the component.
   */
  disabled: PropTypes.bool,
  /**
   * The required state for the component.
   */
  required: PropTypes.bool,
  /**
   * The aria label for the component when a visual label is not provided.
   */
  triggerAriaLabel: isRequiredIf(
    PropTypes.string,
    props => !props.hasOwnProperty("labelText") && !props.hasOwnProperty("labelToken"),
    "'triggerAriaLabel' is required when 'labelText' and 'labelToken' are not defined.'",
  ),
  /**
   * The text for the more information tooltip in the component's label.
   */
  tooltipText: PropTypes.string,
  /**
   * Localized text to display when the select has no options. Defaults to "No options found".
   */
  noOptionsText: PropTypes.string,
}

export default Select
