import { keys, splitAt } from "ramda"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { useTheme } from "@emotion/react"

import tokens from "@ninjaone/tokens"
import { getTextSize, getLineHeight } from "@ninjaone/utils"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"

import Tooltip from "./Tooltip"
import Text from "./Text"

const getTagContainerStyles = ({ wrap }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  gap: tokens.spacing[2],
  ...(wrap && { flexWrap: "wrap" }),
})

const getTagFontStyles = size => ({
  fontSize: getTextSize(size || "sm"),
  fontWeight: tokens.typography.fontWeight.regular,
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: getLineHeight(size || "sm"),
  letterSpacing: "normal",
})

export const getTagStyles = ({ theme, size, color }) => ({
  maxWidth: "200px",
  borderRadius: "2px",
  padding: `2px ${tokens.spacing[2]}`,
  userSelect: "none",
  whiteSpace: "nowrap",
  display: "flex",
  alignItems: "center",
  gap: tokens.spacing[1],
  ...getTagFontStyles(size),
})

const variantStylesGetter = {
  default: theme => ({
    backgroundColor: theme.colorBackgroundAccentNeutralWeak,
    color: theme.colorTextStrong,
  }),
  enabled: theme => ({
    backgroundColor: theme.colorAlertSuccess,
    color: theme.colorTextStrongInverse,
  }),
  disabled: theme => ({
    backgroundColor: theme.colorBackgroundAccentNeutralWeak,
    color: theme.colorTextDisabled,
  }),
  alternate: theme => ({
    backgroundColor: theme.colorBackgroundAccentNeutralDark,
    color: theme.colorTextHighContrast,
  }),
  alertCritical: theme => ({
    backgroundColor: theme.colorAlertError,
    color: theme.colorTextStrongInverse,
  }),
  alertHigh: theme => ({
    backgroundColor: theme.colorAlertWarningStrong,
    color: theme.colorTextStrongStatic,
  }),
  alertMedium: theme => ({
    backgroundColor: theme.colorAlertWarning,
    color: theme.colorTextStrongStatic,
  }),
  alertLow: theme => ({
    backgroundColor: theme.colorAlertWarningWeak,
    color: theme.colorTextStrongStatic,
  }),
}

const variants = keys(variantStylesGetter)

const getVariantStyle = ({ theme, variant = "default" }) => {
  return variantStylesGetter[variant]?.(theme) || {}
}

const StyledTooltipContainer = styled.span`
  padding-top: 2px;
  padding-bottom: 2px;
  ${({ size }) => getTagFontStyles(size)}
`

const Tags = ({
  labels = [],
  maxItemsDisplayed = 0,
  size = "xs",
  renderFullVersion = true,
  mouseEnteredRow,
  wrap = true,
  showRemainingItemsTooltip = true,
  totalCount,
}) => {
  const theme = useTheme()
  const tagStyles = getTagStyles({ theme, size })
  const [items, remainingItems] = splitAt(maxItemsDisplayed || labels.length, labels)

  const lastItem = remainingItems.length
    ? {
        id: "tags-last-item",
        label: localized("+{{remainingItems}} more", {
          remainingItems: totalCount ? totalCount - maxItemsDisplayed : remainingItems.length,
        }),
      }
    : {}

  const getRemainingTagsLabel = () => {
    const remainingTags = remainingItems.slice(0, 10).map(item => <div key={item.id}>{item.label}</div>)

    if (remainingItems.length > 10) {
      remainingTags.push(<div key="more-elements">...</div>)
    }

    return remainingTags
  }

  const showTooltip = !!remainingItems.length && (renderFullVersion || mouseEnteredRow)

  const renderTag = item => {
    const tagContent = (
      <span
        style={{
          ...tagStyles,
          ...getVariantStyle({ variant: item.variant, theme }),
        }}
        key={item.id}
      >
        <Text lineHeight={getLineHeight(size)} {...{ size }}>
          {item.label}
        </Text>
      </span>
    )

    if (item.tooltipLabel && (renderFullVersion || mouseEnteredRow)) {
      return (
        <StyledTooltipContainer size={size} key={item.id}>
          <Tooltip label={item.tooltipLabel}>{tagContent}</Tooltip>
        </StyledTooltipContainer>
      )
    }

    return tagContent
  }

  return (
    items.length > 0 && (
      <div style={getTagContainerStyles({ wrap })}>
        {items.map(renderTag)}

        {showTooltip && (
          <StyledTooltipContainer size={size}>
            {showRemainingItemsTooltip ? (
              <Tooltip label={getRemainingTagsLabel()}>
                <span style={tagStyles}>{lastItem.label}</span>
              </Tooltip>
            ) : (
              renderTag({ id: "tags-last-item", label: lastItem.label })
            )}
          </StyledTooltipContainer>
        )}
      </div>
    )
  )
}

export default Tags

Tags.propTypes = {
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      tooltipLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      variant: PropTypes.oneOf(variants),
    }),
  ),
  maxItemsDisplayed: PropTypes.number,
  wrap: PropTypes.bool,
  size: PropTypes.string,
  renderFullVersion: PropTypes.bool,
  mouseEnteredRow: PropTypes.bool,
  /**
   * Show the tooltip with the remaining items.
   */
  showRemainingItemsTooltip: PropTypes.bool,
  totalCount: PropTypes.number,
}
